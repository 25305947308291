@use "../../abstracts" as abs;

.scroll-h {
  overflow: auto !important;
  /* custom scrollbar */
  &::-webkit-scrollbar {
    width: 20px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #78a2ab;
  }
}
