@use "../../abstracts" as abs;

.treatment-home {
  background-color: #fff;
  border-radius: 36px;

  padding: 53px 0px;

  @include abs.respond-to(mobile) {
    padding: 22px 0px;
    background-color: #fff0;
    border-radius: 0px;
  }

  &>.container>.row {
    margin: 0px;

    background-color: #fff;
    padding: 17px;
    border-radius: 12px;

    &>.col-12 {
      padding: 0px;
    }
  }

  .treatment-track {
    justify-content: center;
    height: 602px;
    overflow: hidden;

    @include abs.respond-to(mobile) {
      // height: fit-content;
      height: auto;
    }

    &.seemore {
      height: fit-content;
      transition: all 0.5s ease;

      @include abs.respond-to(mobile) {
        .dissapear-onmobile {
          display: block !important;
        }
      }
    }

    .treatmentItem {
      display: flex;
      justify-content: center;
      align-items: center;

      &.dissapear-onmobile {
        @include abs.respond-to(mobile) {
          display: none;
        }
      }

      .item {
        background: #ffffff;
        /* Shadow */

        box-shadow: abs.$box-shadow-top;
        border-radius: 18px;
        width: 267px;
        height: 281px;
        padding: 12px;

        text-decoration: none !important;

        @include abs.respond-to(mobile) {
          width: 155px;
          height: 168px;
          padding: 5px;
          border-radius: 12px;
        }

        margin: 10px 0px;

        &:hover {
          border: 1px solid abs.$primary-color;
        }

        .image {
          position: relative;
          text-align: center;

          img {
            max-height: 168px;
            width: 100%;
            border-radius: 12px;
          }

          span {
            position: absolute;
            right: 0px;
            bottom: -2px;
            color: #fff;
            width: -moz-fit-content;
            width: fit-content;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 14px;
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;
            background-color: #223475;
            border: 1px solid #ffffff;
            border-radius: 20px;

            @include abs.respond-to(mobile) {
              font-size: 9px;
              padding: 2px 8px;
              height: 16px;
            }
          }
        }

        .title {
          font-family: abs.$font-family;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          // color: abs.$text-dark;
          color: #242424;
          margin-top: 9px;
          margin-bottom: 5px;
          height: 38px;

          @include abs.respond-to(mobile) {
            height: 34px;
            font-size: 14px;
            line-height: 120%;
            margin-top: 5px;
            margin-bottom: 1px;
          }
        }

        .text {
          font-family: abs.$font-family;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          // color: abs.$text-blue;
          color: #536288;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          @include abs.respond-to(mobile) {
            font-size: 12px;
            line-height: 14px;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
    .see_more{
      span{
        cursor: pointer;
      }
    }
  }
}