@use "../../abstracts" as abs;

.follow-up-list-parent {
  & > .header {
    border-radius: 12px;
    background: #fff;
    padding: 14px 11px;
    margin-top: 11px;
    display: flex;
    align-items: center;
    .img {
      margin-right: 10px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
      }
    }

    span {
      color: abs.$primary-color;
      text-align: center;
      font-family: abs.$font-family;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 27px */
    }
  }

  .list {
    margin-top: 8px;
    padding-bottom: 29px;
    .fup-card {
      display: flex;
      width: 100%;
      padding: 20px 21px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 18px;
      background: #fff;

      .doctor {
        display: flex;
        margin-bottom: 8.5px;
        .img {
          min-width: 35px;
          min-height: 35px;
          max-width: 35px;
          max-height: 35px;
          border-radius: 50%;
          border: 2px solid #f3f5fa;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin-right: 16px;

          img {
            width: 160%;
          }
        }
        .title {
          display: flex;
          flex-direction: column;
          color: abs.$text-gray;
          font-family: abs.$font-family;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          .header {
            color: abs.$primary-color;
            font-family: abs.$font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
          }
        }
      }
      .icn {
        display: flex;
        margin-left: 49px;
        margin-top: 8.5px;
        margin-bottom: 8.5px;
        .img {
          min-width: 32px;
          min-height: 32px;
          max-width: 32px;
          max-height: 32px;
          border-radius: 8px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin-right: 16px;

          img {
            width: 160%;
          }
        }
        .title {
          display: flex;
          flex-direction: column;
          color: abs.$primary-color;
          font-family: abs.$font-family;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          .header {
            color: abs.$text-blue;
            font-family: abs.$font-family;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }

    .swiper-pagination {
      bottom: 1px !important;
      top: unset !important;
    }
  }
}
