@use "../../abstracts" as abs;

.Spaciality_container {
    box-sizing: border-box;
    margin: 20px auto;
    grid-row-gap: 20px;

    .sp_item {
        display: flex;
        justify-content: center;
    }

    .spaciality {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        transition: transform 0.5s;
        border-radius: 12px;
        padding: 0;
        z-index: 0;
        border: 1px solid abs.$corner-color;
        max-width: 370px;

        .img {
            width: 100%;
            transition: transform 0.3s;
            position: relative;
            transform: translateY(-40%);
            aspect-ratio: 367 / 277;
            
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #0F2851, #0000002c);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }
            
            img {
                aspect-ratio: 367 / 277;
                width: 100%;
                border-radius: 12px;
            }

        }

        .card_info {
            position: absolute;
            bottom: 0;
            padding: 15px;
            transition: all 0.3s ease-in-out;

            .info_top {
                display: flex;
                gap: 10px;
                // color: #fff;
                color: #536288;
                transition: all 0.3s ease-in-out;


                img {
                    width: 60px;
                    height: 60px;
                }
            }

            .info_text {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                transition: all 0.3s ease-in-out;
                color: #536288;
                font-size: 16px;


            }

        }

        .view_btn {
            font-family: abs.$font-family;
            font-weight: 600;
            position: absolute;
            z-index: 1;
            left: 10px;
            bottom: 10px;
            padding: 5px 10px;
            text-transform: capitalize !important;
            transform: translateY(200%);
            transition: all 0.3s ease-in-out;
            border: none !important;
        }

        &:hover {
            .img {
                transform: translateY(0);

                &:before {
                    opacity: 1;
                }
            }

            .card_info {
                transform: translateY(-40%);

                .info_text {
                    color: #fff;
                }

                .info_top {
                    color: #fff;
                    gap: 20px;
                    transform: translateX(-80px);
                }
            }

            .info_text {
                margin-bottom: 0;
            }

            .view_btn {
                transform: translateY(0%);
            }
        }
    }
}