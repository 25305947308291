#root {
   
    --dynamic-primary-color: #263077;
    --dynamic-secondary-color: #2cb7df;

}
 
$primary-color: #056535;
$secondary-color: #030303;
$banner-brand: #64a7d6;
$banner-brand2: #f5f5f5;

$corner-color: #ededed;
$card-background-color: #f4f8ff;
$warning-color: #ff4c5e;
$back1-color: #d6eaf9;
$back2-color: #f4f8ff;

$text-primary: #263077;
$text-banner: #263077;
$text-dark: #242424;
$text-white: #fafafa;
$text-gray: #9e9e9e;
$text-blue: #536288;
$text-ash: #b7bfcc;

$box-shadow-top: 0px -5px 30px rgba(0, 0, 0, 0.04);
$box-shadow-bottom: 0px 15px 30px rgba(38, 48, 119, 0.08);
$box-shadow-all: 0px 10px 30px rgba(179, 179, 179, 0.2);

$input-back: #fdfdff;

$sec-2: #ffb201;
$sec-3: #1ebcb4;
$sec-4: #a27fff;
$sec-5: #cfe7ff;
$sec-6: #ccfaf0;
$sec-7: #fef7d8;
$sec-8: #9dc7c4;
$sec-9: #d9a6ff;
$sec-10: #ffcff7;

$cal-avail: #edf8fd;


