@use "../abstracts" as abs;
.heading-home {
  font-family: abs.$font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 160%;
  /* identical to box height, or 45px */

  /* Text Color */

  color: abs.$text-dark;

  @include abs.respond-to(mobile) {
    font-size: 1.125rem;
  }
}

.viewall-home {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-decoration-line: underline;

    /* fd */

    color: abs.$primary-color;

    @include abs.respond-to(mobile) {
      font-size: 0.75rem;
    }
  }
}

.contactus-home {
  .base-cont {
    background-color: abs.$banner-brand;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    .backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0px;
      left: 0px;
      background-repeat: repeat, repeat;
    }

    .left-side {
      z-index: 2;
      margin: 38px 0px 20px 73px;
      @include abs.respond-to(mobile) {
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .address {
        display: block;
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 180%;
        /* identical to box height */

        /* Text Color */
        @include abs.respond-to(mobile) {
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 180%;
          /* identical to box height, or 22px */

          letter-spacing: 0.2px;
        }
        color: abs.$text-white;
      }
      .title {
        display: block;
        font-family: abs.$font-family;
        font-style: normal;

        font-weight: 800;
        font-size: 40px;
        line-height: 48px;

        color: abs.$text-white;
        margin: 12px 0px;
        @include abs.respond-to(mobile) {
          margin: 26.4px 10px 8.46px 10px;

          font-size: 24px;
          line-height: 32px;
          text-align: center;
        }
      }

      .para {
        display: block;
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 180%;
        /* or 25px */

        letter-spacing: 0.2px;

        /* Text Color */

        color: abs.$text-dark;

        margin: 20px 0px;
        @include abs.respond-to(mobile) {
          text-align: center;
          font-size: 12px;
          line-height: 180%;
          margin: 10px 0px;
        }
      }

      button {
        display: block;
      }
    }

    .right-side {
      position: relative;
      height: 330px;
      overflow: hidden;
      @include abs.respond-to(mobile) {
        display: flex;
        justify-content: center;
        height: 200px;
      }
      img {
        position: absolute;
        left: 15px;

        @include abs.respond-to(mobile) {
          left: -34px;
          width: 110%;
        }
      }
    }
  }
}

.download-home {
  .base-cont {
    @include abs.respond-to(mobile) {
      border-radius: 8px;
      margin: 10px 0px 10px 0px;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      z-index: 2;
      margin: 8px 0px 20px 73px;
      @include abs.respond-to(mobile) {
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        align-items: center;
      }

      .uorApp_pill{
        display: flex;
        width: 100%;
        align-items: flex-start;
      }

      .para {
        display: block;
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;

        /* Text Color */

        // color: abs.$text-blue;
        color: #536288;

        margin: 0px 0px 20px 0px;
        @include abs.respond-to(mobile) {
          font-size: 12px;
          line-height: 180%;
          margin: 10px 0px;
          margin-left: 10px;
        }
      }

      button {
        margin-bottom: 27px;
      }
    }

    .right-side {
      position: relative;
      height: 516px;

      @include abs.respond-to(mobile) {
        display: flex;
        justify-content: center;
        height: 200px;
        margin-top: 20px;
      }
      img.app1 {
        position: absolute;
        right: -41px;
        top: 50%;
        transform: translateY(-50%);

        @include abs.respond-to(mobile) {
          left: 50%;
          top: 50%;
          // width: 90%;
          transform: translate(-45%, -22%);
        }
      }
      img.app2 {
        position: absolute;
        right: 191px;
        top: 66%;
        transform: translateY(-59%);

        @include abs.respond-to(mobile) {
          display: none !important;
        }
      }
    }
  }
}
