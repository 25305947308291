@use "../../abstracts" as abs;

.family-memebers {
  background: #fff;
  border-radius: 8px;
  .header {
    padding: 21px 20px 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #263077;
      font-family: abs.$font-family;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
    }

    span.add{
      color: abs.$text-primary;
      font-family: abs.$font-family;
      cursor: pointer;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      img{
        margin-right: 8px;
      }
    }
    border-bottom: 1px solid #f4f8ff;
  }

  .body {
    padding: 20px 20px 21px 20px;

    .dep-item {
      width: 356px;
      height: 148px;
      border-radius: 12px;
      border: 1px solid #ededed;
      background: url(../../../static/profile/familymember/back.svg);

      @include abs.respond-to(mobile) {
        width: 100%;
      }

      .headert {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        .relation {
          position: absolute;
          left: 0px;
          top: 0px;
          border-radius: 20px 20px 20px 0px;
          background: #ffcff7;
          display: inline-flex;
          padding: 6px 20px;
          justify-content: center;
          align-items: center;
          color: abs.$primary-color;
          font-family: abs.$font-family;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .btnr{
            margin-right: 5px;
            margin-top: 8px;
        }
      }
      .icotext {
        display: flex;
        align-items: center;
        margin: 5px 20px;
        .icn {
          width: 19px;
          height: 19px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }

        .txt {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .t1 {
            color: abs.$text-dark;
            font-family: abs.$font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .t2 {
            color: abs.$text-blue;
            font-family: abs.$font-family;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .t3 {
            color: abs.$primary-color;
            font-family: abs.$font-family;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .t4 {
            color: abs.$primary-color;
            font-family: abs.$font-family;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
}
