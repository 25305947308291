@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kreygo');
  src:  url('fonts/icomoon.eot?kreygo#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kreygo') format('truetype'),
    url('fonts/icomoon.woff?kreygo') format('woff'),
    url('fonts/icomoon.svg?kreygo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-carbon_time:before {
  content: "\e92b";
}
.icon-setting-solid:before {
  content: "\e903";
}
.icon-user-setting-solid:before {
  content: "\e904";
}
.icon-folder-solid:before {
  content: "\e905";
}
.icon-user-solid:before {
  content: "\e900";
}
.icon-arrow-left-solid:before {
  content: "\e901";
}
.icon-logout-left-line:before {
  content: "\e902";
}
.icon-ic_round-support-agent:before {
  content: "\e906";
}
.icon-Vector:before {
  content: "\e907";
}
.icon-healthicons_ui-user-profile-outline:before {
  content: "\e908";
}
.icon-button-left-line:before {
  content: "\e911";
}
.icon-edit-line:before {
  content: "\e912";
}
.icon-date-thin-line:before {
  content: "\e910";
}
.icon-avatar:before {
  content: "\e90f";
}
.icon-msg-line:before {
  content: "\e909";
}
.icon-video-line:before {
  content: "\e90a";
}
.icon-home-line:before {
  content: "\e90b";
}
.icon-calendar-line:before {
  content: "\e90c";
}
.icon-wall-line:before {
  content: "\e90d";
}
.icon-chat-line:before {
  content: "\e90e";
}
.icon-home-solid:before {
  content: "\e913";
}
.icon-calendar-solid:before {
  content: "\e914";
}
.icon-wall-solid:before {
  content: "\e915";
}
.icon-chat-solid:before {
  content: "\e916";
}
.icon-plus-solid:before {
  content: "\e917";
}
.icon-like-line:before {
  content: "\e919";
}
.icon-youtube-solid:before {
  content: "\e91a";
}
.icon-linkedin-solid:before {
  content: "\e91b";
}
.icon-twitter-solid:before {
  content: "\e91c";
}
.icon-fb-solid:before {
  content: "\e91d";
}
.icon-inst-solid:before {
  content: "\e91e";
}
.icon-date-fat-line:before {
  content: "\e91f";
}
.icon-clock-line:before {
  content: "\e920";
}
.icon-cloud-line:before {
  content: "\e922";
}
.icon-star-solid:before {
  content: "\e923";
}
.icon-arrow-line:before {
  content: "\e924";
}
.icon-mail-line:before {
  content: "\e925";
}
.icon-phone-solid:before {
  content: "\e926";
}
.icon-location-line:before {
  content: "\e927";
}
.icon-tmh:before {
  content: "\e918";
}
.icon-lc:before {
  content: "\e921";
}
.icon-vc:before {
  content: "\e928";
}
.icon-scv:before {
  content: "\e929";
}
.icon-signout-line:before {
  content: "\e92a";
}
