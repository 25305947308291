@use "../../abstracts" as abs;

.patinetDocs {
  border-radius: 12px;
  background: #CFE7FF;
  padding: 14px 9px;

  .file-status {
    display: flex;
    align-items: center;
    img {
      padding-right: 5px;
    }
    span.counter {
      padding-right: 2.5px;
      color: abs.$secondary-color;
      font-family: abs.$font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
    }
    span.sub {
      color: abs.$text-blue;
      font-family: abs.$font-family;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
  .title {
    font-family: abs.$font-family;
    color: abs.$primary-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .rov-parent {
    border-radius: 8px;
    border: 1px dashed var(--dfdf, #2cb7df);
    background: rgba(255, 255, 255, 0.5);
    padding: 26px 14px 14px 14px;

    .rov {
      font-family: abs.$font-family;
      color: abs.$primary-color;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .para {
      font-family: abs.$font-family;
      color: abs.$text-dark;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 19.5px */
    }
  }
}
