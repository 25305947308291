@use "../../abstracts" as abs;
.apt-dependents {
  padding: 0px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  .header {
    padding: 23px 19px 5px 19px;
    border-bottom: 1px dashed #cdcdcd;
    color: #263077;
    font-size: 18px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;

    .addmem {
      float: right;
      cursor: pointer;
      color: abs.$warning-color;
    }
  }
  .body {
    padding: 23px 19px 23px 19px;
    color: #263077;
    font-size: 18px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;

    .dependant {
      padding-left: 27px;
      position: relative;
      cursor: pointer;
      margin: 7px 0px;
      overflow: hidden;
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 13px;
      }
      span{
        text-overflow: ellipsis; /* enables ellipsis */
        white-space: nowrap; /* keeps the text in a single line */
        overflow: hidden;
      }

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid abs.$text-dark;
        left: 0px;
      }

      &::after {
        content: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid abs.$primary-color;
        background-color: abs.$primary-color;
        left: 4.5px;
      }

      &.checked {
        &::after {
          content: "";
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
