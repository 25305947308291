@use "../../abstracts" as abs;

.price_range {
  padding: 20px;

  .range-slider {
    height: 7px;
    position: relative;
    background-color: #e1e9f6;
    border-radius: 7px;

    .range-selected {
      height: 100%;
      left: 30%;
      right: 30%;
      position: absolute;
      border-radius: 5px;
      background-color: #1b53c0;
    }
  }

  .range-input {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 5px;
      top: -7px;
      background: none;
      pointer-events: none;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;

      &::-webkit-slider-thumb {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 3px solid #1b53c0;
        background-color: #fff;
        pointer-events: auto;
        -webkit-appearance: none;
      }

      &::-moz-range-thumb {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 3px solid #1b53c0;
        background-color: #fff;
        pointer-events: auto;
        -moz-appearance: none;
      }
    }
  }

  .range-price {
    margin: 30px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 18px;
      font-weight: 600;
      color: abs.$primary-color;
    }
  }
}