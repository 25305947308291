@use "../abstracts" as abs;

.tcp {
  margin: 40px 0px;
  border-radius: 12px;
  background: #fff;
  padding: 30px 33px;

  .header-1 {
    color: abs.$text-dark;
    font-family: abs.$font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .para {
    color: abs.$text-dark;
    font-family: abs.$font-family;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.24px;
  }
}
