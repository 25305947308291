@use "../../abstracts" as abs;

.agreement_modal {

    overflow-y: scroll;
    overflow-x: hidden;

    @include abs.respond-to(mobile) {
        // max-height: 500px;

    }

    .closeModal {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        // @include abs.respond-to(mobile) {
        //    left: 10px;

        // }
    }

    .modal_header {
        text-align: center;

        p {
            font-size: 16px;
            font-weight: 400;
            color: abs.$primary-color;
            margin: 30px;
        }
    }

    .rules_container {
        display: flex;
        padding: 15px;
        background-color: #ffbf0036;
        border-radius: 12px;

        .rules {
            color: abs.$sec-2;

            ul {
                color: abs.$text-dark;
            }
        }

        .img {
            max-width: 250px;

            @include abs.respond-to(mobile) {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }

    .button_area {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;

        @include abs.respond-to(mobile) {

            button {
                padding: 10px 20px;
            }
        }

        .cancle {
            background-color: #efeded;
        }
    }

    .bottom_text {
        display: flex;
        justify-content: center;
        width: 100%;

        p {
            max-width: 450px;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: abs.$secondary-color;
        border-radius: 15px;
        border: 1px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: abs.$secondary-color;
    }
}