@use "../abstracts" as abs;

.logincomp {
  position: relative;
  &.activated {
    .MuiPaper-root.MuiDrawer-paper {
      grid-template-rows: 15% 85%;
      grid-template-areas:
        "logos-lgn"
        "content-lgn";

      // .combinedlogin{

      //   overflow-y: scroll;
      // overflow-x: hidden;
      // }
    }
  }

  .closeBtn{
    position: absolute;
    position: absolute;
    right: 9px;
    top: 9px;
  }

  .MuiPaper-root.MuiDrawer-paper {
    overflow: hidden;
    padding: 10px 50px;
    width: 460px;
    height: 100vh;
    border-radius: 12px;
    max-height: 100vh;
    display: grid;
    grid-template-rows: 15% 60% 25%;
    grid-template-areas:
      "logos-lgn"
      "content-lgn"
      "badges-lgn";

    @include abs.respond-to(mobile) {
      width: 100vw;
      padding: 10px;
    }

    .login-logo {
      text-align: center;
      grid-area: logos-lgn;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;

      img{
        width: 100%;
        max-height: 43px;
      }
    }

    .login-badges {
      grid-area: badges-lgn;
      margin: 0px 25px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .combinedlogin {
      grid-area: content-lgn;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .combinedlogin-c {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        .login-phone {
          display: flex;
          flex-direction: column;
          align-items: center;
          .phone-title {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: abs.$text-dark;
            display: block;
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
          }

          .form-login-phone {
            margin-bottom: 36px;

            .phone-no-grp {
              background: abs.$input-back;
              /* Corner */
              margin: 0px;
              border: 1px solid abs.$corner-color;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 55px;

              .inputmix {
                display: flex;
                align-items: center;
                padding-left: 0px;

                span {
                  width: 33px;

                  padding-right: 10px;
                }

                .phoneNumber-login {
                  width: 100%;
                  border: none;
                  background: transparent;
                  font-family: abs.$font-family;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 150%;
                  color: abs.$text-dark;

                  &:focus {
                    outline: none;
                  }
                  &:focus-visible {
                    outline: none;
                  }
                }
              }
            }
          }

          button {
            margin-top: 20px;
          }
        }
        .login-external {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px 0px;

          span.or-login {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            /* identical to box height, or 18px */
            display: block;
            width: 100%;
            text-align: center;

            /* Neutral / 60 */

            color: abs.$text-gray;
            position: relative;

            &::before {
              position: absolute;
              content: " ";
              width: 39%;
              left: 0px;
              height: 1px;
              background-color: abs.$corner-color;
              top: 50%;
            }
            &::after {
              position: absolute;
              content: " ";
              width: 39%;
              right: 0px;
              height: 1px;
              background-color: abs.$corner-color;
              top: 50%;
            }
          }

          button {
            margin-top: 36px;
          }
        }

        .verify-form {
          .text-1 {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            /* identical to box height, or 36px */

            /* fd */

            color: abs.$primary-color;
            text-align: center;
            margin-bottom: 31px;
          }
          .text-2 {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height, or 162% */

            text-align: center;

            /* fd */

            color: abs.$text-gray;
          }
          .text-3 {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height, or 162% */

            text-align: center;

            /* fd */

            color: abs.$text-gray;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 26px;

              color: abs.$primary-color;
            }
          }

          .otp-boxes {
            div {
              justify-content: center;
              margin-top: 24px;
              input {
                background: #ffffff;
                /* Text 5 */
                height: 49px;
                width: 49px !important;
                border: 1px solid #9e9e9e;
                border-radius: 4.23005px;
                margin: 0px 5px;
              }
            }
          }

          .resend-code {
            display: block;
            margin-top: 20px;
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.02em;
            text-decoration-line: underline;
            cursor: pointer;

            /* fd */

            color: abs.$primary-color;
          }
        }

        .startup-form {
          .text-1 {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            /* identical to box height, or 36px */

            /* fd */

            color: abs.$primary-color;
            text-align: center;
            margin-bottom: 0px;
          }
        }
      }

      .login-pp {
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* or 16px */

        text-align: center;
        margin-top: 36px;
        display: block;
        /* Text/60 */

        color: abs.$text-gray;

        a {
          text-decoration: none;
          color: abs.$primary-color;
        }
      }
    }
  }
}
