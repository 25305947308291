@use "../../abstracts" as abs;

.banners-home {
  background-color: #fff;

  .carousel-caption {
    text-align: left !important;
    bottom: unset;
    right: unset;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);

    text-decoration: none;

    h3 {
      font-family: abs.$font-family;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      color: abs.$text-banner;
      width: 50%;

      @include abs.respond-to(mobile) {
        font-size: 16px;
        line-height: 24px;
        width: 66%;
      }
    }

    p {
      font-family: abs.$font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      // color: abs.$text-dark;
      color: #242424;
      margin-top: 23px;
      width: 30%;

      @include abs.respond-to(mobile) {
        font-size: 10px;
        margin-top: 0px;
        width: 62%;
        margin-bottom: 0px;
      }
    }

    .btnr {
      margin-top: 49px;

      @include abs.respond-to(mobile) {
        margin-top: 14px;
      }
    }
  }

  .banner-container-web,
  .banner-container-mobile {
    .carousel {
      .carousel-control-prev {
        transform: translateX(-80px);
      }

      .carousel-control-next {
        transform: translateX(80px);
      }

      .carousel-control-prev,
      .carousel-control-next {
        opacity: 1;

        span {
          border-radius: 100%;
          width: 45px;
          height: 45px;
          background-size: 60% 60%;
          opacity: 0;
          transition: opacity 0.3s ease, transform 0.3s ease;
          background-color: abs.$primary-color;

          @include abs.respond-to(mobile) {
            display: none;
          }
        }
      }

      &:hover {
        .carousel-control-prev span {
          opacity: 1;
          transform: translateX(80px);
        }

        .carousel-control-next span {
          opacity: 1;
          transform: translateX(-80px);
        }
      }
    }
  }

  .carousel-indicators {
    button {
      background-color: #e1e3e8 !important;
      opacity: 1 !important;

      border-top: 0px !important;
      border-bottom: 0px !important;
      border-radius: 20px;
      width: 53px;
      height: 6px;

      @include abs.respond-to(mobile) {
        width: 16px;
        height: 3px;
      }

      &.active {
        background-color: abs.$primary-color !important;
        opacity: 1 !important;
      }
    }

    @include abs.respond-to(mobile) {
      // bottom: -20px;
    }
  }
}
