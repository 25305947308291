@use "../../abstracts" as abs;

.aboutus-home {
  background-color: #fff;
  border-radius: 36px;

  padding: 53px 0px;

  @include abs.respond-to(mobile) {
    padding: 0px;
    background-color: transparent;
    border-radius: 0px;
  }

  .about-grid {
    display: grid;
    grid-template-areas: "abgaslider abgacontent" "abgaslider abgastrip";

    grid-template-columns: 40% 60%;

    @include abs.respond-to(mobile) {
      padding: 20px;
      background-color: #fff;
      border-radius: 18px;

      grid-template-areas: "abgacontent" "abgaslider" "abgastrip";
      grid-template-columns: 100%;
    }

    .abcontent {
      grid-area: abgacontent;
      margin-left: 50px;

      @include abs.respond-to(mobile) {
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: 0px;
      }

      .section-title.section-title-primary {
        margin-bottom: 1px;

        @include abs.respond-to(mobile) {
          margin-bottom: 18px;
        }
      }

      .para {
        display: block;
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #242424;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 30px;

        @include abs.respond-to(mobile) {
          font-size: 12px;
          line-height: 180%;
          /* or 18px */
          -webkit-line-clamp: 4;
          letter-spacing: 0.135706px;
          margin-bottom: 10px;
        }
      }

      button {
        @include abs.respond-to(mobile) {
          display: none;
        }
      }
    }

    .btnr.btnr-primary {
      margin-bottom: 10px;
    }

    .abslider {
      grid-area: abgaslider;
      margin-top: 38px;

      @include abs.respond-to(mobile) {
        margin-top: 0px;
      }

      .slick-slider {
        margin-bottom: 43px;

        img {
          border-radius: 12px;
        }

        .slick-dots {
          li {
            background: abs.$primary-color;
            opacity: 0.3;
            border-radius: 100px;
            width: 9px;
            height: 7px;

            &.slick-active {
              opacity: 1;
              width: 22px;
            }

            button {
              &::before {
                top: -6px;
                left: -4px;
                opacity: 0 !important;
              }
            }
          }
        }
      }
    }

    .abstrip {
      grid-area: abgastrip;
      margin-left: 50px;

      @include abs.respond-to(mobile) {
        margin-left: 0px;
      }

      .abs_grid {
        display: flex;
        flex-direction: column;
        align-items: center;

        .txt{
          font-size: 14px;
        }

        .count{
          font-size: 20px;
        }

        @include abs.respond-to(mobile) {
          align-items: center;
          text-align: center;
          padding: 0px 20px;
        }

        img {
          max-width: 60px;
        }
      }

      .count {
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

        color: #263077;
        margin-top: 12px;
      }

      .txt {
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;

        color: abs.$text-blue;

        @include abs.respond-to(mobile) {
          margin-bottom: 10px;
        }
      }
    }
  }
}