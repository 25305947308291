@use "../../abstracts" as abs;
.apt-Summary {
  padding: 23px 19px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  .apt-type {
    background-color: abs.$card-background-color;
    border-radius: 12px;
    padding: 10px 9px;

    color: #263077;

    font-size: 16px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    img {
      width: 32px;
      height: auto;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .icon-text {
    display: flex;
    align-items: center;
    margin: 15px 0px;

    .icon {
      border-radius: 10px;
      border: 5px solid #fff0;
      background: #fff0;
      margin-right: 17px;
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .txt {
      color: abs.$text-dark;
      font-size: 12px;
      font-weight: 600;
      font-family: abs.$font-family;
      display: flex;
      flex-direction: column;

      span {
        color: abs.$text-gray;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 3px;
      }
    }
  }
}
