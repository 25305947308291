@use "../../abstracts" as abs;
.appttype-parent {
  padding: 23px 19px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  @include abs.respond-to(mobile) {
    // padding: 0px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .h1 {
    color: #263077;
    font-size: 14px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
  }

  .apptTypes {
    display: flex;
    align-items: center;
    justify-content: start;

    .type {
      width: 50%;
      height: 70px;
      padding: 10px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      img {
        width: 32px;
      }

      span {
        color: abs.$text-gray;
        font-size: 10px;
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }

      .checked {
        display: none;
        position: absolute;
        top: 5px;
        right: 5px;
        width: 24px;
      }

      &.type1 {
        background-color: abs.$corner-color;
      }
      &.type2 {
        background-color: abs.$corner-color;
      }
      &.checked {
        background-color: abs.$secondary-color;

        span{
          color: abs.$text-white;
        }

        .checked {
          display: block;
        }
      }
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .treatmentdiv {
    display: flex;
    align-items: center;
    padding: 7px 5px;
    background-color: #f2f4f7;
    border-radius: 8px;
    .img {
      width: 60px;
      height: 41px;
      border-radius: 6px;
      margin-right: 9px;

      img {
        max-width: 60px;
        width: auto;
        height: 41px;
      }
    }
    .txt {
      flex-grow: 1;
      color: abs.$primary-color;
      font-family: abs.$font-family;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .price {
      flex-grow: 1;
      color: abs.$primary-color;
      font-family: abs.$font-family;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      width: fit-content;
      text-align: end;
    }
  }
}
