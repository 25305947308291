@use "../abstracts" as abs;

.treatment-main {
  .upper {
    margin: 40px 0px 20px 0px;
    border-radius: 36px;
    background: #fff;
    padding: 24px 30px;

    @include abs.respond-to(mobile) {
      padding: 11px 11px;
      border-radius: 17.557px;
      .row-rev {
        flex-direction: column-reverse;
      }

      .btnr {
        margin: 0px auto;
        display: flex;
      }
    }

    .header {
      color: abs.$primary-color;
      font-family: abs.$font-family;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;

      @include abs.respond-to(mobile) {
        font-size: 20px;
        margin-top: 15px;
      }
    }

    .doctor-parrent {
      margin: 30px 0px;
      display: flex;
      align-items: center;

      @include abs.respond-to(mobile) {
        border-radius: 12px;
        background: rgba(244, 248, 255, 0.58);
        padding: 21px 15px;
        margin-top: 15px;
      }

      .icon {
        width: 65.5px;
        height: 65.5px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;

        @include abs.respond-to(mobile) {
          width: 46.25px;
          height: 46.25px;
          margin-right: 15px;
        }

        img {
          width: 130%;
        }
      }

      .txt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .t1 {
          color: abs.$text-dark;
          font-family: abs.$font-family;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;

          @include abs.respond-to(mobile) {
            font-size: 14px;
          }
        }
        .t2 {
          color: #263077;
          font-family: abs.$font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.2px;

          @include abs.respond-to(mobile) {
            font-size: 12px;
          }
        }
        .t3 {
          color: abs.$text-blue;
          font-family: abs.$font-family;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.2px;

          @include abs.respond-to(mobile) {
            font-size: 10px;
          }
        }
      }
    }

    .img {
      display: flex;
      justify-content: center;

      img {
        border-radius: 21.127px;
        width: 100%;
      }

      overflow: hidden;
    }

    .about-h {
      border-radius: 30px;
      background: rgba(237, 237, 237, 0.4);

      color: abs.$text-blue;
      font-family: abs.$font-family;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      padding: 17px 30px;
      margin-top: 25px;
      margin-bottom: 33px;

      @include abs.respond-to(mobile) {
        display: none;
      }
    }

    .about-p {
      color: abs.$text-blue;
      font-family: abs.$font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      padding: 0px 30px;

      @include abs.respond-to(mobile) {
        margin-top: 20px;
        padding: 0px 12px;
      }
    }
  }
  .gall {
    margin: 20px 0px;
    .gal-card {
      width: 292px;
      margin: 16px;
      border-radius: 30.4163px;
      img {
        width: 100%;
        border-radius: 12.376px;
      }

      @include abs.respond-to(mobile) {
        width: 246px;
      }
    }
  }

  .tlist {
    .treatment-track {
      justify-content: center;
      height: 602px;
      overflow: hidden;

      @include abs.respond-to(mobile) {
        height: fit-content;
      }
      &.seemore {
        height: fit-content;
        transition: all 0.5s ease;

        @include abs.respond-to(mobile) {
          .dissapear-onmobile {
            display: block !important;
          }
        }
      }

      .treatmentItem {
        display: flex;
        justify-content: center;
        align-items: center;

        &.dissapear-onmobile {
          @include abs.respond-to(mobile) {
            display: none;
          }
        }

        .item {
          background: #ffffff;
          /* Shadow */
          text-decoration: none !important;

          box-shadow: abs.$box-shadow-top;
          border-radius: 18px;
          width: 267px;
          height: 281px;
          padding: 12px;

          @include abs.respond-to(mobile) {
            width: 155px;
            height: 168px;
            padding: 5px;
            border-radius: 12px;
          }

          margin: 10px 0px;

          &:hover {
            border: 1px solid abs.$primary-color;
          }

          .image {
            position: relative;
            text-align: center;
            img {
              max-height: 168px;
              width: 100%;
            }

            span {
              position: absolute;
              right: 0px;
              bottom: -10px;
              color: #fff;
              width: fit-content;
              height: 22px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px 10px;
              font-family: abs.$font-family;
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 120%;
              background-color: abs.$primary-color;

              border: 1px solid #ffffff;
              border-radius: 20px;

              @include abs.respond-to(mobile) {
                font-size: 9px;
                padding: 2px 8px;
                height: 16px;
              }
            }
          }

          .title {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: abs.$text-dark;
            margin-top: 9px;
            margin-bottom: 5px;
            height: 38px;

            @include abs.respond-to(mobile) {
              height: 34px;
              font-size: 14px;
              line-height: 120%;
              margin-top: 5px;
              margin-bottom: 1px;
            }
          }

          .text {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: abs.$text-blue;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include abs.respond-to(mobile) {
              font-size: 10px;
              line-height: 14px;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
  }
}


.explore_treatments{
  .tlist {
    .treatment-track {
      justify-content: start;
      max-height: 602px;
      overflow: hidden;

      @include abs.respond-to(mobile) {
        height: fit-content;
      }
      &.seemore {
        height: fit-content;
        transition: all 0.5s ease;

        @include abs.respond-to(mobile) {
          .dissapear-onmobile {
            display: block !important;
          }
        }
      }

      .treatmentItem {
        display: flex;
        justify-content: center;
        align-items: center;

        &.dissapear-onmobile {
          @include abs.respond-to(mobile) {
            display: none;
          }
        }

        .item {
          background: #ffffff;
          /* Shadow */
          text-decoration: none !important;

          box-shadow: abs.$box-shadow-top;
          border-radius: 18px;
          width: 267px;
          height: 281px;
          padding: 12px;

          @include abs.respond-to(mobile) {
            width: 155px;
            height: 168px;
            padding: 5px;
            border-radius: 12px;
          }

          margin: 10px 0px;

          &:hover {
            border: 1px solid abs.$primary-color;
          }

          .image {
            position: relative;
            text-align: center;
            img {
              max-height: 168px;
              width: 100%;
            }

            span {
              position: absolute;
              right: 0px;
              bottom: -10px;
              color: #fff;
              width: fit-content;
              height: 22px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px 10px;
              font-family: abs.$font-family;
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 120%;
              background-color: abs.$primary-color;

              border: 1px solid #ffffff;
              border-radius: 20px;

              @include abs.respond-to(mobile) {
                font-size: 9px;
                padding: 2px 8px;
                height: 16px;
              }
            }
          }

          .title {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: abs.$text-dark;
            margin-top: 9px;
            margin-bottom: 5px;
            height: 38px;

            @include abs.respond-to(mobile) {
              height: 34px;
              font-size: 14px;
              line-height: 120%;
              margin-top: 5px;
              margin-bottom: 1px;
            }
          }

          .text {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: abs.$text-blue;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include abs.respond-to(mobile) {
              font-size: 10px;
              line-height: 14px;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
  }
}