@use "../abstracts" as abs;

.select_Spaciality_container {
    background-color: #fff;
    margin: 20px auto;
    border-radius: 12px;

    @include abs.respond-to(mobile) {
        &.container {
            padding: 0px;
        }
    }

    .header {
        h2 {
            color: #263077;
            padding: 20px;
        }

        @include abs.respond-to(mobile) {
            font-size: 18px;
        }

        .filter_area {
            display: flex;
            background-color: #f4f8ff;
            margin: 20px 0;
            padding: 10px;
            border-radius: 45px;
            align-items: center;
            gap: 10px;

            @include abs.respond-to(mobile) {
                flex-direction: column;
                gap: 10px;
            }

            .search {
                display: flex;
                gap: 5px;
                align-items: center;
                background-color: #fff;
                border-radius: 25px;
                padding: 5px 10px;
                width: 360px;

                @include abs.respond-to(mobile) {
                    width: 100%;
                }

                input {
                    border: none;
                    padding: 5px 10px;
                    width: 100%;
                    color: abs.$primary-color;
                    font-weight: 500;
                    outline: none;
                }

                svg {
                    fill: abs.$primary-color;
                }
            }

            .location {
                display: flex;
                gap: 5px;
                align-items: center;
                background-color: #fff;
                border-radius: 25px;
                padding: 5px 10px;
                width: 360px;

                @include abs.respond-to(mobile) {
                    width: 100%;
                }


                select {
                    padding: 5px 10px;
                    border: none;
                    border-radius: 12px;
                    width: 100%;
                    outline: none;

                    option {
                        padding: 10px 10px;
                        border: none;
                        outline: 1px solid abs.$primary-color;
                        border-radius: 8px;
                        width: 100%;
                    }

                }
            }
        }
    }


}