@use "../abstracts" as abs;

.walllist {
  overflow: unset !important;
  margin-top: 39px;

  @include abs.respond-to(mobile) {
    margin-top: 20px;
  }

  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @include abs.respond-to(mobile) {
    max-width: 540px;
  }

  @include abs.respond-to(handheld) {
    max-width: 720px;
  }

  @include abs.respond-to(smallDesktop) {
    max-width: 768px;
  }

  @include abs.respond-to(largeDesktop) {
    max-width: 822px;
  }

  @include abs.respond-to(extraLargeDesktop) {
    max-width: 822px;
  }

  .wall-parent {
    background-color: #fff;
    border: 1px solid abs.$corner-color;
    position: relative;
    /* Shadow */
    z-index: 0;

    box-shadow: abs.$box-shadow-top;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 43px 40px;

    @include abs.respond-to(mobile) {
      padding: 15px;
    }

    .wp-top {
      margin-bottom: 21px;

      .profile-wall {
        display: flex;
        align-items: center;

        .pw-g-i {
          width: 52px;
          height: 52px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 160%;
            height: auto;
          }
        }

        .pw-g-t {
          padding-left: 12px;

          .name {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 16px;
            /* identical to box height, or 91% */

            display: flex;
            align-items: center;

            /* Text Color */

            color: abs.$text-dark;

            @include abs.respond-to(mobile) {
              font-size: 16px;
              line-height: 16px;
            }
          }

          .time {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height, or 91% */

            display: flex;
            align-items: center;

            /* Text Color */
            margin-top: 9.5px;
            color: abs.$text-gray;

            @include abs.respond-to(mobile) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .wp-middle {
      text-align: center;

      .Read_more{
        font-weight: 600;
        color: abs.$primary-color;
        cursor: pointer;
      }

      p {
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        color: abs.$text-dark;
      }

      img {
        max-width: 100%;
        border-radius: 8px;
        margin-bottom: 20px;
      }

      .buttonsList {
        display: flex;

        margin: 15px 0px;

        @include abs.respond-to(mobile) {
          margin: 20px 0px;
        }

        .like-btn {
          padding-right: 25px;
          cursor: pointer;
          display: flex;
          align-items: center;

          font-family: abs.$font-family;
          font-style: normal;
          font-weight: 700;
          font-size: 14.7913px;
          line-height: 16px;

          color: abs.$text-dark;

          @include abs.respond-to(mobile) {
            font-size: 12px;
            line-height: 16px;
          }

          span {
            font-size: 20px;
            padding-right: 5px;
          }

          &.active {
            color: abs.$primary-color;
          }
        }

        .comment-btn {
          cursor: pointer;
          display: flex;
          align-items: center;

          font-family: abs.$font-family;
          font-style: normal;
          font-weight: 700;
          font-size: 14.7913px;
          line-height: 16px;

          color: abs.$text-dark;

          @include abs.respond-to(mobile) {
            font-size: 12px;
            line-height: 16px;
          }

          span {
            font-size: 20px;
            padding-right: 5px;
          }
        }
      }
    }

    .wp-bottom {
      .ipcomment {
        display: flex;
        align-items: center;
        margin-bottom: 36px;

        .user-photo {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;

            @include abs.respond-to(mobile) {
              width: 48px;
              height: 48px;
            }
          }

          margin-right: 15px;

          @include abs.respond-to(mobile) {
            margin-right: 14px;
          }
        }

        .s_user_input{
          display: flex;
          width: 100%;
          gap: 20px;
        }

        .user-input {
          flex: 1;
          display: flex;
          align-items: center;
          border-radius: 25px;
          border: 1px solid abs.$corner-color;

          input {
            flex: 1;
            margin: 0px;
            height: 48px;
            /* display: block; */
            padding: 0px 10px;
            border: none;
            outline: none !important;
            border-radius: 25px;

            @include abs.respond-to(mobile) {
              max-width: 152px;
            }
        
            &::placeholder {
              color: initial; 
            }
          }

          .error{
            border-color: red;
              border: 1px solid red;
              border-radius: 8px;
        
              &::placeholder {
                color: red;
              }
          }
        

        }
      }

      .user-cmts {
        display: flex;
        flex-direction: column;

        .comment-parent {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;

          .profile-wall {
            display: flex;
            align-items: center;

            @include abs.respond-to(mobile) {
              align-items: flex-start;
            }

            .pw-g-i {
              width: 48px;
              height: 48px;
              overflow: hidden;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 160%;
                height: auto;
              }
            }

            .pw-g-t {
              padding-left: 12px;

              .name {
                font-family: abs.$font-family;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                /* identical to box height, or 91% */

                display: flex;
                align-items: center;

                /* Text Color */

                color: #263077;

                @include abs.respond-to(mobile) {
                  margin-bottom: 5px;
                }
              }

              .cmt {
                font-family: abs.$font-family;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 91% */

                display: flex;
                align-items: center;

                /* Text Color */
                margin-top: 2.5px;
                color: abs.$text-dark;
              }
              .comment-input{
                border-radius: 8px ;
                border: 1px solid abs.$corner-color;
                padding: 8px;
                width: 300px;
                @include abs.respond-to(mobile) {
                  max-width:200px;
                }
              }
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }

          .load-more {
            font-family: abs.$font-family;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration-line: underline;
            color: abs.$primary-color;
            cursor: pointer;
          }

          .button-wall {
            display: flex;
            justify-content: space-between;
            position: relative;

            .dropdown {
              transition-duration: 0.3s;
              border-radius: 10px;

              button {
                background-color: transparent;
                padding: 10px 15px;
                border-radius: 0 !important;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;

                img {
                  width: 25px;
                }
              }
              .save_btn{
                background-color: abs.$card-background-color;
                border-radius: 12px !important;
              }
            }

            .dropdown:hover{
              background-color: abs.$card-background-color;

              .drop-menu{
                transform: scaleY(1);
              }
            }

            .drop-menu{
              display: flex;
              flex-direction: column;
              position: absolute;
              z-index: 100;
              top: 0;
              right: 50px;
              border-radius: 10px;
              border: 1px solid #E5E5E5;
              background-color: abs.$card-background-color;
              transform: scaleY(0);
              transform-origin: top;
              transition-duration: 0.3s;
              
              .drop-item{
                padding: 10px 15px;
                text-decoration: none;
                width: 170px;
                color: #242424;
                border-radius: 12px;
              }
              .drop-item:hover{
                background-color: #E5E5E5;
              }

              .drop-item:last-child{
                color: red;
              }
            }
            
          }
        }
      }
    }
  }
}