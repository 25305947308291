@use "../../abstracts" as abs;

.treatmentParent {
  padding: 23px 19px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  .h1 {
    color: #263077;
    font-size: 16px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
    display: block;
  }

  .dropper {
    border-radius: 8px;
    background: var(--background-color, #f4f8ff);

    padding: 12px 22px 12px 12px;

    .phone-tf {
      .MuiInputBase-root.MuiInput-root {
        &::before {
          content: none !important;
        }
        &::after {
          content: none !important;
        }

        input {
          color: abs.$text-dark;
          font-size: 12px;
          font-family: abs.$font-family;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          display: block;
          margin-top: 5px;
        }
      }
    }
  }
}
.treatment-popper {
  z-index: 8000;
  .MuiPaper-root.MuiPaper-elevation {
    width: 100% !important;

    .MuiAutocomplete-option {
      display: flex;
      .img {
        width: 60px;
        height: 41px;
        overflow: hidden;
        border-radius: 6px;
        margin-right: 10px;

        img {
          width: 100%;
        }
      }

      .name {
        flex: 1;
        color: abs.$text-dark;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-family: abs.$font-family;
      }
      .price {
        width: 50px;
        color: abs.$text-dark;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-family: abs.$font-family;
        text-align: end;
      }

      &.Mui-focused {
        .name {
          color: abs.$primary-color;
        }
        .price {
          color: abs.$primary-color;
        }
      }
    }
  }
}
