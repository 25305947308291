@use "../abstracts" as abs;

.userapptDetails-main {
  margin: 40px 0px;

  @include abs.respond-to(mobile) {
  }

  .appt-state {
    border-radius: 12px;
    background: abs.$sec-9;
    padding: 15px 10px;

    &.vc {
      background: abs.$sec-8;
    }

    .header {
      background: rgba(255, 255, 255, 0.43);

      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 52px;
      margin-bottom: 17px;

      .treatment-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-right: 10px;
      }

      .ipimg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .doc {
      .col-3 {
        display: flex;
        justify-content: center;
        .img {
          width: 48px;
          height: 48px;
          border-radius: 100%;
          overflow: hidden;
          border: 3px solid #fff;
          background-size: 160%;
          background-position: center;

          img {
            width: 160%;
          }
        }
      }

      .col-9 {
        display: flex;
        flex-direction: column;
        color: #fff;
        font-family: abs.$font-family;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: 0.25px;

        span {
          color: #fff;
          font-family: abs.$font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.44px;
        }
      }
    }

    .body {
      .det-txt {
        color: abs.$text-blue;
        font-family: abs.$font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 8px;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .sidenav {
    position: relative;

    @include abs.respond-to(mobile) {
      margin-top: 10px;
    }
    border-radius: 12px;
    background: #fff;

    .header {
      background: var(
        --linera,
        linear-gradient(90deg, #eafeff 0%, #fffbf1 100%)
      );
      color: #263077;
      text-align: start;
      font-family: abs.$font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      width: 100%;
      padding: 10px 19px 10px 19px;
    }

    .body {
      margin-top: 24px;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
      padding: 0px 19px 16px 19px;
      .img {
        border-radius: 50%;
        width: 120px;
        height: 120px;
      }

      .name {
        color: #263077;
        font-family: abs.$font-family;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .det {
        color: abs.$text-dark;
        font-family: abs.$font-family;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 0px;
        margin-bottom: 18px;
      }

      .icon-text {
        display: flex;
        align-items: center;
        margin: 0px 0px 10px 0px;
        width: 100%;

        .icon {
          border-radius: 10px;
          border: none;
          background: #fff0;
          margin-right: 7px;
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;
          justify-content: center;
          img {
            width: 100%;
          }
        }

        .txt {
          color: abs.$text-dark;
          font-size: 12px;
          font-weight: 600;
          font-family: abs.$font-family;
          display: flex;
          flex-direction: column;

          span {
            color: abs.$text-blue;
            font-size: 11px;
            font-weight: 500;
            margin-bottom: 3px;
          }
        }
      }
      .icon-text-dark {
        display: flex;
        align-items: center;
        margin: 0px 0px 10px 0px;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        background: #f4f8ff;

        .icon {
          border-radius: 10px;
          border: none;
          background: #fff0;
          margin-right: 7px;
          display: flex;
          align-items: center;
          width: 40px;
          min-width: 40px;
          height: 40px;
          justify-content: center;
          img {
            width: 100%;
          }
        }

        .txt {
          color: abs.$warning-color;
          font-size: 12px;
          font-weight: 500;
          font-family: abs.$font-family;
          display: flex;
          flex-direction: column;

          span {
            color: abs.$primary-color;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 3px;
          }
        }
      }

      .noofappt {
        padding: 18px 56px;
        width: 100%;
        height: 114px;
        border-radius: 12px;
        background: var(
          --linera,
          linear-gradient(90deg, #eafeff 0%, #fffbf1 100%)
        );
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          color: abs.$primary-color;
          font-family: abs.$font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: block;
          margin-top: 11px;
        }
      }
    }
  }

  .appt-parent {
    @include abs.respond-to(mobile) {
      margin-top: 10px;
    }
    .appt-dettails {
      border-radius: 12px;
      background: #fff;

      .header {
        padding: 13px 24px;
        color: abs.$text-dark;
        font-family: abs.$font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        background: #fafafa;
      }

      .body {
        padding: 16px 24px;

        

        & > .row {
          padding: 16px 0px 16px 0px;
          position: relative;

          .rs-app{
            border-radius: 8px;
            background:  #FEF7D8;
            margin-top: 15px;
            padding: 12px 13px;
            color: abs.$primary-color;
            font-family: abs.$font-family;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .txt {
            display: flex;
            flex-direction: column;
            color: abs.$text-blue;
            font-family: abs.$font-family;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            span {
              color: abs.$text-dark;
              font-family: abs.$font-family;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            &.txt2 {
              span {
                color: #263077;
                font-family: abs.$font-family;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: block;
                margin-bottom: 10px;
              }
            }
          }

          .head {
            color: #263077;
            font-family: abs.$font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: block;
            margin-bottom: 10px;
          }

          .recomdation-img-list {
            display: flex;

            a {
              margin: 0px 7px;
              border-radius: 12px;
              background: #f4f8ff;
              padding: 10px;
              width: 145px;
              height: 191px;
              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: auto;
                max-height: 100%;
              }

              &:first-child {
                margin-left: 0px;
              }
            }
            div {
              margin: 0px 7px;
              border-radius: 12px;
              background: #f4f8ff;
              padding: 10px;
              width: 145px;
              height: 191px;
              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: auto;
                max-height: 100%;
              }

              &:first-child {
                margin-left: 0px;
              }
            }
          }

          .img-gal {
            height: 0px !important;
            width: 0px !important;
            visibility: hidden !important;
            overflow: hidden !important;
          }

          .pdfViewer {
            position: fixed;
            height: 100%;
            width: 100%;
            background: #464646d9;
            display: none;
            top: 0px;
            left: 0px;
            z-index: 2000;

            &.open {
              display: block;
            }
          }
          .rec-text {
            color: abs.$text-dark;
            font-family: abs.$font-family;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            border-radius: 8px;
            background: rgba(237, 237, 237, 0.2);
            padding: 15px;
          }

          .icon-text {
            display: flex;
            align-items: center;
            margin: 0px 0px 10px 0px;
            width: 100%;

            .icon {
              border-radius: 10px;
              border: none;
              background: #fff0;
              margin-right: 7px;
              display: flex;
              align-items: center;
              width: 40px;
              height: 40px;
              justify-content: center;
              img {
                width: 100%;
              }
            }

            .txt {
              display: flex;
              flex-direction: column;

              span.t1 {
                color: abs.$text-blue;
                font-size: 11px;
                font-weight: 500;
                margin-bottom: 3px;
              }

              span.t2 {
                color: abs.$text-dark;
                font-size: 14px;
                font-weight: 600;
                font-family: abs.$font-family;

                .t3 {
                  color: #263077;
                  padding-left: 5px;
                }
              }
            }
          }

          .cardaptytpe {
            @include abs.respond-to(mobile) {
              margin: auto;
              margin-top: 20px;
            }

            background-color: abs.$sec-9;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 5px;
            border-radius: 12px;
            width: 131px;
            height: 83px;
            color: #263077;
            text-align: center;
            font-family: abs.$font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            &.green {
              background-color: abs.$sec-8;
            }
            img {
              margin-bottom: 12px;
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
          }

          &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background-color: abs.$sec-5;
            top: 0px;
            left: 0px;
          }

          &:first-child {
            padding: 0px 0px 16px 0px;

            &::before {
              content: none;
            }
          }
        }
      }
    }
    .gen-inst {
      border-radius: 12px;
      background: #fff;

      margin-top: 20px;
      overflow: hidden;

      .header {
        padding: 13px 24px;
        color: abs.$text-dark;
        font-family: abs.$font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        background: rgba(255, 207, 247, 0.37);
      }

      .body {
        padding: 16px 24px;

        .icon-text {
          display: flex;
          align-items: center;
          margin: 0px 0px 10px 0px;
          width: 100%;

          .icon {
            border-radius: 10px;
            border: none;
            background: #fff0;
            margin-right: 7px;
            display: flex;
            align-items: start;
            width: 40px;
            height: 40px;
            justify-content: center;
            img {
              width: 100%;
            }
          }

          .txt {
            display: flex;
            flex-direction: column;

            span.t1 {
              font-family: abs.$font-family;
              color: abs.$primary-color;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 3px;
            }

            span.t2 {
              color: abs.$text-blue;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              font-family: abs.$font-family;
            }
          }
        }
      }
    }
  }

  .appt-doc-profile {
    width: 100%;
    padding: 17px 13px;
    border-radius: 15.441px;
    background: #fff;
    font-family: abs.$font-family;

    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      overflow: hidden;
      width: 100%;
      border-radius: 15.441px;

      img {
        width: 100%;
      }
    }

    .body {
      width: 100%;

      .qualification {
        color: abs.$primary-color;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        font-family: abs.$font-family;
      }

      .designation {
        font-size: 16px;
        color: #263077;
        font-weight: 700;
        line-height: 120%;
        margin: 8px 0px 12px 0px;
        font-family: abs.$font-family;
      }

      .name {
        font-size: 20px;
        color: abs.$text-dark;
        font-weight: 700;
        line-height: 120%;
        margin-top: 15.7px;
        font-family: abs.$font-family;
      }

      .icon-text {
        display: flex;
        align-items: center;
        margin: 15px 0px;

        .icon {
          border-radius: 10px;
          border: 5px solid #fff;
          background: #fff;
          box-shadow: 0px 10px 30px 0px rgba(178, 178, 178, 0.2);
          margin-right: 17px;
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;
          justify-content: center;

          .star {
            width: 18px;
          }

          .dlr {
            width: 12px;
          }
        }

        .txt {
          color: abs.$text-dark;
          font-size: 12px;
          font-weight: 600;
          font-family: abs.$font-family;
          display: flex;
          flex-direction: column;

          span {
            color: abs.$text-gray;
            font-size: 11px;
            font-weight: 500;
            margin-bottom: 3px;
          }
        }
      }
    }
  }
}
