@use "../../abstracts" as abs;

.notiffdrop-parent {
  display: grid;
  width: 40px;
  height: 48px;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  align-items: center;

  @include abs.respond-to(mobile) {
    width: 40px;
    grid-template-columns: 100%;
    grid-template-areas: "usrimg";
    margin-right: 10px;
  }



  .notifdrop-menu {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(38, 48, 119, 0.08);

    border-radius: 12px;
    overflow: hidden;
    top: 100%;
    width: 100%;
    margin-top: 18px;
    z-index: 3;
    min-width: 450px;
    left: -195px;

    @include abs.respond-to(mobile) {
      // display: none;
      min-width: 310px;
      right: 0;
      margin-top: 10px;
    }

    .header {
      color: abs.$text-blue;
      padding: 20px 30px 10px 30px;
      font-family: abs.$font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 125% */
      border-bottom: 3px solid #F4F8FF;

      span {
        float: right;
        color: #1A1F36;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        text-decoration: underline;
      }
    }

    .list {
      height: 400px;
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;

      .notif-item {
        display: block;
        text-decoration: none !important;
        padding: 20px 10px;
        border-bottom: 1px solid #bcbcbc;

        .col-2 {
          position: relative;

          span {
            font-size: 24px;
            margin-top: 7px;
            display: block;
            color: abs.$primary-color;

          }
        }

        .title {
          color: abs.$text-dark;
          font-family: abs.$font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 24px */
        }

        .notif_status {
          font-family: abs.$font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;


        }

        .notif_status.primaryColor {
          color: abs.$primary-color
        }

        .notif_status.seaGreen {
          color: #22C55E !important;
        }

        .notif_status.purpleLite {
          color: #A27FFF !important;
        }

        .notif_status.yellow {
          color: #FFB201;
        }

        .notif_status.purple {
          color: #A27FFF !important;
        }

        .notif_status.greenLite {
          color: #9DC7C4 !important;
        }

        .notif_status.warningColor {
          color: abs.$warning-color !important;
        }

        .notif_status.blue {
          color: #2cb7df !important;
        }

        .subtitle {
          color: abs.$text-gray;
          font-family: abs.$font-family;

          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          /* 20.8px */

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .msg-time {
          color: abs.$text-gray !important;
          font-family: abs.$font-family;

          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          line-height: 150% !important;
          /* 18px */
        }

        &:hover {
          background-color: abs.$card-background-color;
        }

        &.notread {
          background-color: abs.$card-background-color;

          .img {

            &::before {
              content: " ";
              position: absolute;
              width: 5px;
              height: 5px;
              background-color: red;
              border-radius: 50%;

            }

          }
        }
      }

      .no-notiff {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;

        .img {
          img {
            max-width: 100%;
          }
        }

        .no-notiff-text {
          text-align: center;

          h2 {
            color: #263077;
            color: abs.$primary-color;
          }

          span {
            color: #536288;
            color: abs.$text-blue
          }
        }
      }

      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 15px;
        border: 1px solid transparent;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
      }
    }
  }
}